.map {
    position: relative;
    display: flex;
    background-color: white;
    height: 600px;
}

.mapWrapper {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
}

.mapWrapper > h2 {
    font-weight: 600;
    font-size: 3em;
    line-height: 1.23;
    padding: 20px;
    color: black;
    text-align: center;
}

@media (max-width: 980px) {
    .mapWrapper {
        width: 100%;
    }
}
