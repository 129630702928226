.slider {
    position: relative;
    display: flex;
    background-color: white;
}

.sliderWrapper {
    margin: 0 auto;
    width: 100%;
}

.sliderArrow {
    width: 45px;
    height: 45px;
    background: rgb(204, 163, 0);
    border: solid 2px rgb(204, 163, 0);
    border-radius: 50%;
    padding: 10px;
    margin: 20px;
    transition: .3s;
}

.sliderArrow svg {
    fill: white;
    transition: .3s;
}

.sliderArrow:hover {
    background-color: white;
}

.sliderArrow:hover svg {
    fill: rgb(204, 163, 0);
}

.sliderArrow:disabled {
    display: none;
}

.slide * {
    background-color: #fdfdfd;
}

.slide {
    height: 100vh;
}

.slide img {
    width: 100%;
}

.slideImage, .slideImage * {
    width: 100%;
}

@media (max-width: 980px) {
    .sliderWrapper {
        width: 100%;
    }
}
