.main {
    min-height: 100vh;
    position: relative;
    display: flex;
    padding: 10px;
    box-sizing: border-box;
}

.main:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: -webkit-linear-gradient(top, rgba(4, 8, 20, 0.6), rgba(4, 8, 20, 0.9));
    width: 100%;
    height: 100%;
    z-index: 0;
}

.mainWrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    z-index: 1;
    gap: 50px;
}

.mainWrapper > h3 {
    font-weight: 700;
    font-size: 2.4em;
    text-align: center;
}

.mainWrapper > p {
    line-height: 1.5;
    font-size: 1.3em;
    font-weight: 300;
    text-align: center;
}

.mainCounters {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 35px;
    text-align: center;
}

.mainCounters li {
    flex: 1;
}

.mainCounters span {
    padding-top: 8px;
    padding-bottom: 20px;
    font-size: 3.3em;
    font-weight: 600;
}

.mainCounters hr {
    margin-top: 30px;
    background-color: #cca300;
    opacity: 0.8;
    height: 1px;
    outline: none;
    border: none;
}

.mainCounters p {
    font-weight: 300;
    margin-top: 20px;
}

.mainButtons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mainButtonsPrimary {
    color: #ffffff;
    background-color: var(--main-first);
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    font-size: 0.95em;
    font-weight: 700;
    background-image: none;
    cursor: pointer;
    padding: 20px 50px;
    border: solid 2px transparent;
    box-sizing: border-box;
    transition: .3s;
}

.mainButtonsPrimary:hover {
    background-color: transparent;
    border: solid 2px #1a9c68;
    color: #1a9c68;
}

@media (max-width: 980px) {
    .mainWrapper {
        width: 100%;
    }

    .mainCounters li {
        flex:none;
    }
}
