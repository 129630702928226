.errorBlock {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
}

.errorWrapper > p {
    font-size: 2.5em;
    font-weight: 900;
}

.errorWrapper > button {
    margin-top: 25px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: var(--main-second);
    border: 3px solid transparent;
    color: #fff;
    cursor: pointer;
    transition: .1s;
}

.errorWrapper > button:hover {
    background-color: var(--main-first);
    border-bottom: 3px solid var(--main-first);
}
