.about {
    min-height: 100vh;
    position: relative;
    display: flex;
    background-color: white;
}

.aboutWrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    gap: 50px;
    padding: 100px 0;
}

.aboutWrapper > h2 {
    font-weight: 600;
    font-size: 3em;
    line-height: 1.23;
    color: black;
    text-align: center;
}

.aboutWrapper > p {
    line-height: 1.5;
    font-size: 1.4em;
    font-weight: 300;
    text-align: center;
    color: black;
    max-width: 700px;
    margin-top: 10px;
}

.aboutWrapper ul {
    text-align: left;
}

@media (max-width: 980px) {
    .aboutWrapper {
        width: 100%;
    }
}
