.button {
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border: none;
    border-bottom: 3px solid var(--main-second);
    outline: none;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: var(--main-second);
    color: #fff;
    cursor: pointer;
    transition: .1s;
}

.button:hover {
    background-color: var(--main-first);
    border-bottom: 3px solid var(--main-first);
}
