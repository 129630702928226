.cookieAlert {
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 10000;
    bottom: 0px;
    width: 100%;
    background-color: rgba(25, 25, 25, 0.5);
    color: white;
    padding: 5px;
    box-sizing: border-box;
    font-size: .8em;
}

.cookieAlertWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
    gap: 10px;
}

.cookieAlertClose {
    cursor: pointer;
}

.cookieAlertClose:hover {
    color: rgba(255, 255, 255, 0.75);
}

.cookieAlertActions {
    display: flex;
    justify-content: center;
    gap: 25px;
}

.cookieAlertActions > a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 7px;
    background-color: #0c183b;
    border-radius: 8px;
    color: white;
    transition: .1s;
}

.cookieAlertActions > a:hover {
    background-color: #1f5bff;
}

.cookieAlertActions > button {
    padding: 10px;
    background-color: var(--main-first);
    border-radius: 8px;
    color: white;
    outline: none;
    border: none;
    cursor: pointer;
    transition: .1s;
}

.cookieAlertActions > button:hover {
    background-color: #cca300;
}

@media (max-width: 480px) {
    .cookieAlertWrapper {
        flex-direction: column;
    }

    .cookieAlertActions {
        flex-direction: column;
        gap: 5px;
    }
}

@media (max-width: 720px) {
    .cookieAlert {
        width: 100%;
    }

    .cookieAlertActions > a {
        line-height: 3em;
        padding: 0px 5px;
    }
}
