@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;900&display=swap');

:root {
    --main-first: #0072b5;
    --main-first-dark: #0c4470;
    --main-second: #fa7319;
}

* {
    margin: 0;
    padding: 0;
    list-style-type: none;
    text-decoration: none;
}

html {
    overflow-x: hidden;
}

body {
    height: 100%;
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
    font-size: 1.1em;
}

h1, h2, h3 {
    font-family: Roboto;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: var(--main-first) #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: var(--main-first);
    border-radius: 10px;
    border: 3px solid #ffffff;
}

/* Disable animate.css on small devices */
@media only screen and (max-width: 768px) {
    .animate__animated {
        /*CSS transitions*/
        -o-transition-property: none !important;
        -moz-transition-property: none !important;
        -ms-transition-property: none !important;
        -webkit-transition-property: none !important;
        transition-property: none !important;
        /*CSS transforms*/
        -o-transform: none !important;
        -moz-transform: none !important;
        -ms-transform: none !important;
        -webkit-transform: none !important;
        transform: none !important;
        /*CSS animations*/
        -webkit-animation: none !important;
        -moz-animation: none !important;
        -o-animation: none !important;
        -ms-animation: none !important;
        animation: none !important;
    }
}

/* Slider */
.react-slideshow-container+ul.indicators {
    position: absolute;
    bottom: 0;
    left: -50%;
    right: -50%;
}

.react-slideshow-container+ul.indicators .each-slideshow-indicator:before {
    background-color: white !important;
    width: 10px !important;
    height: 10px !important;
}
