.financial {
    position: relative;
    display: flex;
    background-color: white;
}

.financialWrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    gap: 50px;
    padding: 25px 0;
}

.financialWrapper > h2 {
    font-weight: 600;
    font-size: 3em;
    line-height: 1.23;
    color: black;
    text-align: center;
}

.financialContent{
    color: black;
    width: 50%;
    margin: 0 auto;
}

.financialContent ul {
    color: black;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 20px;
}

.financialContent ul li {
    padding: 30px;
    box-shadow: 0px 0px 5px 1px #d2d2d2;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: .1s;
}

.financialContent ul li:hover{
    box-shadow: 0px 0px 10px 2px #c2c2c2;
}

@media (max-width: 980px) {
    .financialWrapper {
        width: 100%;
    }
}
