.footer {
    width: 100%;
    background-color: rgba(4, 8, 20, 1);
    text-align: center;
    padding: 40px 0 10px;
}

.footer a {
    color: white;
    text-align: center;
    transition: .3s;
}

.footer a:hover{
    color: #cbcbcb;
}
