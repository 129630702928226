.error {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 2em;
    color: white;
}

.errorWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
}

.errorWrapper > h1 {
    font-size: 1.2em;
}

.errorWrapper > h3 {
    font-size: 2em;
}

.errorNav {
    width: 100%;
    margin-top: 30px;
    border-top: solid 2px white;
}

.errorNav ul {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.errorNav a{
    color: white;
    font-size: .7em;
    transition: .1s;
}

.errorNav a:hover{
    color: var(--main-second);
}
