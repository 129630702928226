.projectWidget {
    position: fixed;
    color: white;
    padding: 10px 15px;
    border-radius: 20px;
    font-size: 0.95em;
    font-weight: 500;
    box-sizing: border-box;
    cursor: pointer;
    transition: .3s;
    z-index: 1000000;
}

.projectWidget:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 1px #0c4470;
    box-sizing: border-box;
    border-radius: 40px;
    animation: pulse 3s infinite;
    z-index: -1;
}

.projectWidget:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 1px #0072b5;
    box-sizing: border-box;
    border-radius: 40px;
    animation: pulse 2s infinite;
    z-index: -1;
}

.projectWidget:hover {
    transform: scale(1.03);
}

.projectWidget:hover:before {
    animation: none;
}

.projectWidget:hover:after {
    animation: none;
}

.projectWidgetModalWrapper {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(21, 21, 21, 0.75);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000000;
}

.projectWidgetModal {
    width: 50%;
    min-height: 50%;
    max-width: 720px;
    min-width: 300px;
    background-color: white;
    border-radius: 10px;
    padding: 25px;
    box-sizing: border-box;
    position: relative;
}

.projectWidgetModalTitle {
    text-align: center;
    font-size: 1.3em;
    font-weight: 500;
}

.projectWidgetModalClose {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    transition: .3s;
}

.projectWidgetModalClose:hover {
    transform: rotate(180deg);
}

.projectWidgetModalContent {
    height: 100%;
    padding-top: 15px;
}

.projectWidgetModalEnded {
    text-align: center;
    color: #c70000;
    font-weight: 600;
    font-size: 2em !important;
}

.projectWidgetModalEndedWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
}

.projectWidgetModalEndedWrapper > a {
    padding: 7px 15px;
    background-color: var(--main-first);
    border-radius: 10px;
    color: white;
    transition: .1s;
}

.projectWidgetModalEndedWrapper > a:hover {
    background-color: var(--main-second);
}

.projectWidgetModalInfoFirst {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 80%;
    margin: 0 auto;
}

.projectWidgetModalInfoFirst > ul {
    flex: 1;
}

.projectWidgetModalInfoFirst > ul > li {
    font-size: 1.1em;
    font-weight: 400;
    padding: 5px 0px;
}

.projectTokenRate {
    text-align: center;
    font-size: 1.75em;
    font-weight: 600;
    padding: 20px 0;
    color: #252525;
}

.projectTokenRate > span {
    color: var(--main-first-dark);
}

.projectWidgetModalActions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
}

.projectWidgetModalActions label {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 5px;
    text-align: left;
    flex: 1;
}

.projectWidgetModalActions input {
    flex: 1;
    border: solid 2px #949494;
    border-radius: 6px;
    margin: 0;
    min-height: 38px;
    font-size: 1.2em;
    padding: 7px;
    box-sizing: border-box;
}

.projectWidgetModalActionsButton {
    width: 100%;
    padding: 10px 15px;
    background-color: var(--main-first);
    border-radius: 10px;
    color: white;
    transition: .1s;
    cursor: pointer;
    outline: none;
    border: none;
    font-size: 0.8em;
    transition: .3s;
    cursor: pointer;
}

.projectWidgetModalActionsButton:hover {
    background-color: var(--main-second);
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.3);
        opacity: 0;
    }
}

@media (max-width: 768px) {
    .projectWidgetModalInfoFirst {
        flex-direction: column;
    }
}
